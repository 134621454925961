<template>

    <div id="app" class="white pt-1">

       <div class="primary pa-2 mt-2" v-if="aberto"
        style="border-radius: 8px;"
       >
    

    <v-dialog
     v-model="aberto"
     :width="(isMobile) ? '350' : '920'"
     :persistent="true"
     @click:outside="abrir()"
     
     
    >

    <!-- pro_modo_edicao:{{ pro_modo_edicao }} <br/> -->
    <!-- {{ obj_user }} -->
    <!-- {{ altura_rodape }} -->
    <!-- {{ selected }} -->
<!-- {{ items_lista }} -->
<!-- pro_obj_1:{{ pro_obj_1 }} <br/> -->
<!-- pro_dados_paciente:{{ pro_dados_paciente }} <br/><br/> -->
<!-- {{ pro_dados_proceds }} -->
<!-- {{ pro_tabela }} -->
<!-- pro_pode_alterar:{{ pro_pode_alterar }} -->
<!-- pro_tag: {{ pro_tag }} <br/> -->
<!-- mostrar_rodape: {{ mostrar_rodape }} <br/> -->
<!-- item_edita: {{ item_edita }} <br/><br/> -->

    <v-dialog
          v-model="dialog_tabela"
          persistent
          max-width="990"
          scrollable
      >

      <v-card class="">

        <!-- ANTIGO -->
      <!-- <v-card-title>
          <span class="text-h5" 
            v-text="(item_edita.CODIGO>0) ? 'Alterar '+ card_titulo +' '+item_edita.CODIGO : 'Novo '+ card_titulo "
          >
          Alterar
          </span>
      </v-card-title> -->



      <!-- <span v-if="(vai_imprimir)">
        <v-card-title class="mb-1 text-h5 d-flex align-center justify-center" >{{obj_dados_empresa.NOME}}</v-card-title>
        <v-divider class="mx-7"></v-divider>
      </span> -->

      
      <!-- <v-card-title v-if="(vai_imprimir)" class="mb-1 text-h6 d-flex align-center justify-center ">{{pro_dados_paciente.NOME}}</v-card-title>
      <v-card-title v-else class="mb-1 text-h6 grey lighten-4">{{pro_dados_paciente.NOME}}</v-card-title> -->

      <v-card-title v-if="(vai_imprimir)" class="mb-1 text-h6 d-flex align-center justify-center ">{{card_titulo}}</v-card-title>
      <v-card-title v-else class="mb-1 text-h6 grey lighten-4">{{card_titulo}}</v-card-title>

      <v-card-subtitle class="grey lighten-4 pb-2 mb-1">

          <!-- <span class="no-print mr-1 blue--text" v-text="(item_edita.CODIGO>0) ? 'Alterar ' : 'Novo '"/>   -->

          <span class="text-h5 no-print blue--text mr-2">
             {{ ((item_edita.CODIGO>0) ? 'Alterar ': 'Novo ') }}  
          </span>

          <!-- <span class="text-h6 ml-n2">
              {{ (card_titulo !='Registros') ? card_titulo : '' }}  
          </span> -->

          <span class="no-print" v-if="(item_edita.CODIGO>0)">
            {{item_edita.CODIGO}}
          </span>
          

      </v-card-subtitle>

          <!-- passo 4 monto os campos editaveis -->
          <!-- :readonly = "readonly" -->
              <v-card-subtitle :class="['pa-1', (def_parcelas.length>0) ? 'grey lighten-5':'white']">
                <v-form
                  ref="form"
                  v-model="valido"
                  >
                  <v-container>

                      <v-row >
                          <v-col
                              :class="[item.class_col]"
                              v-for="(item, i) in items_editaveis"
                              :key="i"
                              :cols="(isMobile) ? 12 : item.cols"
                              v-if="parseInt(item.cols)>0"   
                          >

                          <v-span v-if="(item.tag == 'v-span')">
                            {{  item_edita[item.value] }}
                          </v-span>  
                        

                          <v-card class="grey lighten-5"  elevation="4"
                             v-if="item.tag == 'v-editor'"
                            >
                              <v-card-text>
                                  <editor-content
                                    :editor="editor"
                                    class="white lighten-5"
                                    :style="[{ minHeight: altura_meio + 'cm'}]"
                                    v-if="item.tag == 'v-editor'"
                                  />
                              </v-card-text>
                            </v-card>


                            <p v-if="item.tag == 'p'" :class="[item.class]"
                             v-html="item_edita[item.value]">
                            </p>


                            <span v-if="(item.tag == 'v-img')&&(item_edita[item.value]?.length >10)" style="display: block; text-align: center;">
                              <!-- caso esteja preenchido mostra..caso contrario ele mostra o botao para upload -->
                              <v-divider class="mx-10 my-4"/>
                              <img
                                v-if="(item.tag == 'v-img')&&(item_edita[item.value].length >10)"
                                alt="user"
                                :src="item_edita[item.value]"
                                :width="[item.width]"
                              >
                              </img>
                            </span>


                            <span v-if="(item.tag == 'v-chip-value')">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                    v-if="(item.tag == 'v-chip-value')" :color="item.color" @click="clicou_em(item.click)"
                                    :class=[item.class]
                                    v-bind="attrs" v-on="on"
                                    >
                                      {{ item_edita[item.value] }}
                                    </v-chip>
                                  </template>
                                  <span> {{item.hint}} </span>
                                </v-tooltip>
                              </span>


                              <span v-if="(item.tag == 'v-chip')">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                    v-if="(item.tag == 'v-chip')" :color="item.color" @click="clicou_em(item.click)"
                                    :class=[item.class]
                                    v-bind="attrs" v-on="on"
                                    >
                                      {{ item.text }}
                                    </v-chip>
                                  </template>
                                  <span> {{item.hint}} </span>
                                </v-tooltip>
                              </span>


                              <span  v-if="(item.tag == 'v-checkbox')">
                                <v-checkbox 
                                      v-model="item_edita[item.value]"
                                      :label="(item_edita[item.value] ? item.text : item.text_2)"
                                      dense
                                      :class="[ (item_edita[item.class_if]) ? item.class_tag :  item.class_tag2]"
                                      :style= "[item.style_tag]"
                                      @click="clicou_em(item.click)"
                                      hide-details="true"
                                      v-if="(!item.if) || (item_edita[item.if])"
                                  >
                                  
                                  <!-- style="margin-left: -10px;display: none " -->
                                  <!-- :style= "[item.style_tag]" -->
                                  
                                  <!-- v-if="( (item_edita.hasOwnProperty(item.if) && item_edita[item.if]) || (item.else && !item_edita[item.else]) )" -->
                                      <!-- <template v-slot:label>
                                        <span class="fs-8">{{ item.text }}??</span>
                                      </template> -->
                                  </v-checkbox>
                                </span>



                                <!-- <span v-if="(item.tag == 'v-text') && (item.vmask)"> -->
                                <span v-if="(item.tag == 'v-text')">
                                  <v-text-field
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.rule == 'S' ? item.text+' *': item.text"
                                      :type="item.type"
                                      v-if="(!item.if) || (item_edita[item.if])"
                                      @keyup="meu_keyup($event, item.value, item.upper), clicou_em((item.click)||false)"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      v-mask = "getVmask(item.vmask)"
                                      :class="[item.class + item.class_tag]"
                                      :autofocus="(item.focus=='S')"
                                  >
                                </v-text-field>
                                </span> 

                                 <!-- COM V-MASK -->
                                 <!-- <v-text-field 
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.rule == 'S' ? item.text+' *': item.text"
                                      :type="item.type"
                                      v-if="(item.tag == 'v-text') && (item.vmask)"
                                      @keyup="meu_keyup($event, item.value, item.upper)"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      :autofocus="i==1"
                                      :hint="item.hint"
                                      v-mask = "getVmask(item.vmask)"
                                  ></v-text-field> -->
                                  
                                  <!-- SEM V-MASK  acaba dando erro, por isso separei-->
                                  <!-- <v-text-field
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.rule == 'S' ? item.text+' *': item.text"
                                      :type="item.type"
                                      v-if="(item.tag == 'v-text') && (!item.vmask)"
                                      @keyup="meu_keyup($event, item.value, item.upper)"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      :autofocus="i==1"
                                      :hint="item.hint"
                                  ></v-text-field> -->



                                <v-combobox
                                    v-model="item_edita[item.value]"
                                    dense
                                    :label="item.text"
                                    :items="item.tag_items"
                                    :item-value='item.item_value'
                                    :item-text='item.item_text'
                                    :return-object="false"
                                    @change="onChange(item.tag_change)"
                                    v-if="item.tag == 'v-combobox'"
                                    :rules="item.rule == 'S' ? notnullRules: noRules"
                                >

                                    <template slot='selection' slot-scope='{ item }'>
                                        <span class="fs-12">
                                            <!-- {{ (item.item_text)? item.item_text : item }}?? -->
                                            {{ item }}
                                        </span>
                                    </template>

                                    <!-- <template v-slot:label>
                                        <span class="red--text">Compartilhar com..</span>
                                    </template> -->
                                </v-combobox>


                                <v-combobox
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.text"
                                  v-if="item.tag == 'v-autocomplete'"
                                  :items="item.tag_items"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"
                                  :class="[item.class_tag]"

                                  clearable
                                  :return-object = 'item.return_object'
                                  :item-value='item.item_value'
                                  :item-text='item.item_text'
                                  :autofocus="(item.focus=='S')"
                              ></v-combobox>
                              
                              <v-select
                                  :class="[item.class]"
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.text"
                                  v-if="item.tag == 'v-select'"
                                  :items="item.tag_items"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"

                                  item-value='CODIGO'
                                  item-text='NOME'
                                  :clearable = "item.tag_clear == 'S'"
                                  :persistent-hint="item.cols>2"
                                  :hint="item.hint"
                                  @change="clicou_em(item.click)"
                              >
                              
                              <!-- <template slot="selection" v-slot-scope="item"> -->
                                
                              <!-- <template slot="selection" v-slot-scope="item"> -->
                                <template v-slot:label>
                                  <v-tooltip bottom v-if="item.hint">
                                      <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            v-on="on"
                                        > 
                                          <span :class="item.class">
                                            {{ item.text }} 
                                            <!-- {{ item_edita[item.value] }} -->
                                          </span>
                                        </span>

                                      </template>
                                      <span> {{ item.hint }} </span>
                                    </v-tooltip>
                                </template>
    
                            </v-select>


                              <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                              <v-textarea
                                v-model="item_edita[item.value]"
                                :label="item.text"
                                v-if="item.tag == 'v-textarea'"
                                :hint="item.hint"
                                :persistent-hint="item.cols>2"
                                dense
                                solo
                                :rows="item.rows"
                                :autofocus="i==2"
                                :rules="item.rule == 'S' ? notnullRules: noRules"
                                :outlined="(vai_imprimir) ? false : true"
                              >
                              </v-textarea>


                              
                              
                          </v-col>
                      </v-row>
                  </v-container>
                </v-form>
              </v-card-subtitle>

              <v-card-text class="pa-0 pl-0">
                      <v-card-text>
                          <!-- <v-container> -->
                              <v-row class="" dense
                              v-for="(item, i) in def_parcelas"
                              :key="i"
                              >
                              <!-- {{ def_parcelas }} -->
                              <!-- {{ item }}                      -->
                              <!-- {{edita_parcelas}} -->

                                     
                                  <span  class="mt-3">
                                      {{ parseInt(i+1)}}ª 
                                      <!-- {{ 1-inicio + parseInt(i)}}ª  -->
                                  </span>   

                                  <v-col
                                  cols="6"
                                  md="2"
                                  >
                                      <v-text-field
                                          v-model="item.VALOR_UM"
                                          type=""
                                          label="Valor"
                                          prefix="R$"
                                          dense
                                          @keyup="item.VALOR_UM = moeda($event), digitou_entrada(item)"
                                      >
                                      <!-- :disabled="def_parcelas.length==i+ parseInt(1)"     -->
                                      

                                      </v-text-field>
                                  </v-col>

                                  &nbsp
                                  &nbsp


                                  <v-col
                                  cols="6"
                                  md="2"
                                  >

                                      <v-select
                                      v-model="item.FORMA_PAGAMENTO"
                                      label="Forma..."
                                      :items="items_tabela_formas"
                                      item-value='DESCRICAO'
                                      item-text='DESCRICAO'
                                      :return-object="false"
                                      dense
                                      :clearable = "false"
                                      >
                                      
                                      <template slot='selection' slot-scope='{ item }'>
                                          <span class="fs-12"> {{ (item.DESCRICAO)? item.DESCRICAO : item }}</span>
                                      </template>
                                      </v-select>

                                  </v-col>

                                  &nbsp
                                  &nbsp

                                  <v-col
                                  cols="6"
                                  md="2"
                                  >
                                  <v-text-field
                                      v-model="item.DATA"
                                      type="date"
                                      label="Vencimento"
                                      dense
                                  >
                                  </v-text-field>
                                  </v-col>

                                  &nbsp
                                  &nbsp

                                  <v-col
                                  cols="6"
                                  md="2"
                                  >
                                  <v-text-field
                                      v-model="item.DATA_EFETUADO"
                                      type="date"
                                      label="Efetuado"
                                      dense
                                  >
                                  </v-text-field>
                                  </v-col>

                                  &nbsp
                                  &nbsp


                                  <v-col
                                  cols="3"
                                  >
                                  <v-text-field
                                      v-model="item.CATEGORIA"
                                      type=""
                                      label="Categoria"
                                      dense
                                      class="fs-12"
                                      :rules="notnullRules"
                                      @keyup="item.CATEGORIA=item.CATEGORIA.toUpperCase()"
                                  >
                                  </v-text-field>
                                  </v-col>

                              </v-row>
                          <!-- </v-container> -->
                      </v-card-text>
              </v-card-text>


      <v-card-actions>
          <v-btn
              x-small
              color="red"
              text
              @click="clicou_em('excluir')"
          >
          <!-- @click="remover_tabela(tabela_selecionada, item_edita, card_titulo), dialog_tabela = false" -->
            Excluir
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              color="red"
              text
              @click="clicou_em('cancelar')"
          >
            Cancelar
          </v-btn>
          
          &nbsp

          <v-btn
              :disabled="!valido"
              color="primary"
              @click="clicou_em('salvar')"
          >
          <!-- @click="salvar_tabela(tabela_selecionada, item_edita, 'dialog_tabela') -->
            Salvar
          </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>


      <v-container
      :class="[cor_fundo, 'pa-4']"
      >    

        <v-row>
            <v-col
            :cols="(isMobile) ? '10' : '11'"
            class="pa-0 pt-2 "
            >

                <v-card
                height="40"
                :class="[cor_fundo]"
                elevation="0"
                >

                    <!-- <h4 class="white--text ml-2"> <v-icon color="white">mdi-bell</v-icon> Lembretes</h4> -->
                    <h4 class="white--text ml-2"> <v-icon color="white"> {{ this.icone_tabela_sel }}  </v-icon> {{ card_titulo }}  </h4>


                </v-card>
            </v-col>



            

            <v-col
            :cols="(isMobile) ? '2' : '1'"
            :class="[isMobile ? 'pa-0 mt-2' : 'pa-0 mt-2']"
            >
                <v-icon
                color="white"
                :class="(isMobile) ? 'ml-7' : 'ml-8' "
                @click="abrir()"
                >
                mdi-close
                </v-icon>
            </v-col>
        </v-row>

        <v-card class="mt-1 mb-1 pa-1"
            elevation="4"
            :height="retorna_altura(200)"
            v-if="(!pro_modo_edicao)"
            >
            <!-- :height="(isMobile) ? '420' : '500' " -->

            <v-container>
                <v-row>
                    <v-col
                    cols="12"
                    md="2"
                    class=""
                    v-if="(pro_obj_menus)"
                    >
                    <v-card
                        class="mx-auto"
                    >
                        <v-list         
                            dense
                            nav
                        >
                            <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                            active-class="blue--text"
                            >
        
                                <v-divider
                                    class="mx-0"
                                ></v-divider>
        
                                <!-- passo 2  mostro os items da lista -->
                                <v-list-item
                                    v-for="(item, i) in lista_tabelas"
                                    :key="i"
                                    @click="card_titulo=item.text, tabela(item.click, item)"
                                    class="pa-0 mr-1 "
                                >
                                    <v-list-item-icon class="pa-0 mr-2">
                                    <v-icon v-text="item.icon"></v-icon>
                                    </v-list-item-icon>
        
                                    <v-list-item-content>
                                        <!-- <v-list-item-title v-text="item.text"> -->
                                        <v-list-item-title>
                                        {{ item.text }}

                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
        
                                <v-divider
                                    class="mx-4"
                                ></v-divider>
        
                            </v-list-item-group>
                        </v-list>
                    </v-card>    


                    </v-col> 
        
        
                    <v-col
                    class="grey lighten-5"
                    v-if="(!pro_modo_edicao)"
                    >
                    <v-card
                        class="mx-auto"
                    >
                    <!-- v-if="items_lista.length > 0" -->

                        <v-card-title
                        class="pa-1 pl-2"
                        v-if="sub_codigo>0"
                        >
                            <!-- <h4 class="primary--text" @click="ultimo_click()" >{{ sub_descricao }} </h4> -->
                            <v-chip
                            color="primary"
                            @click="ultimo_click()"
                            >
                            {{ sub_descricao }}
                            </v-chip>
                            &nbsp
                            <h5 class="mb-2 grey--text">{{ sub_codigo }} </h5>

                            &nbsp

                            <v-chip
                            color="primary"
                            @click="ultimo_click()"
                            >
                            VOLTAR
                            </v-chip>

                        </v-card-title>

                        <v-card-title
                            v-if="card_titulo"
                            class="grey lighten-3"
                        >
  
                            <v-row dense v-if="pro_tabela=='CLIENTES'" class="">
                                <v-col class="" md="4" cols="6">
                                    <v-text-field
                                      v-model="nome_digitou"
                                      :label="`Nome ou IDE: (${items_lista.length})`"
                                      required
                                      append-icon="mdi-magnify "
                                      clearable            
                                      hide-details  
                                      @keypress.enter="tabela('CLIENTES')"
                                      @click:append="tabela('CLIENTES')"
                                      @keyup.esc="nome_digitou=''"    
                                      autofocus


                                    ></v-text-field>

                                    <div class="text-center">
                                    <v-progress-linear
                                      value="0"
                                      indeterminate
                                      color="blue"
                                      v-if="progress_user"
                                    />
                                  </div>
                                </v-col>

                                  <v-col class="ml-2 pt-5" cols="2">
                                    <v-checkbox
                                      v-model="find_start"
                                      label="Iniciando"
                                      dense
                                      class=""
                                      style="margin-left: 8px"   
                                      hide-details
                                  ></v-checkbox>
                                  </v-col>


                                  <v-col class="" md="3" cols="6">
                                    <v-text-field
                                      v-model="cidade_digitou"
                                      label="Cidade"
                                      clearable            
                                      hide-details
                                      @keypress.enter="tabela('CLIENTES')"
                                      @click:append="tabela('CLIENTES')"
                                      @keyup.esc="cidade_digitou=''"

                                    ></v-text-field>
                                </v-col>

                            </v-row>
                                
                            <v-row dense v-else>
                                <v-col class=""  cols="12" md="8">
                                    <v-row class="">
                                        <v-col>
                                            <span class="text-h6 black--text" style="font-size:16px !important"> {{ card_titulo }} </span>
                                            <!-- <span > {{ card_titulo }} </span> -->
                                            <span class="red--text mb-2 ml-1"> {{ items_lista.length }} </span>

                                        </v-col>


                                        <v-col cols="4" md="2" v-if="pro_tabela=='ALERTA'">
                                            <v-checkbox
                                                    v-model="antigos"
                                                    label="Tudo"
                                                    dense
                                                    style="margin-left: -4px"
                                                    class="pa-0"
                                                    hide-details="true"
                                                    @click="tabela('ALERTA','')"
                                                >
                                                <template v-slot:label>
                                                <span class="blue--text ml-n2" style="font-size:12px !important">Antigos</span>
                                                </template>
                                            </v-checkbox>
                                        </v-col>
                                        
                                    </v-row>

                                </v-col>


                                <v-col class=""  cols="12" md="4">
                                    <v-row class="">
                                        <v-col class="" cols="8" md="9" >
                                            <v-text-field
                                                dense
                                                v-model="search"
                                                clearable
                                                append-icon="mdi-magnify"
                                                label="Procurar"
                                                single-line                
                                                hide-details
                                                @keyup.esc="search=''"  

                                                >
                                            </v-text-field>
                                            
                                        </v-col>

                                        <!-- <v-spacer></v-spacer> -->

                                        <v-col cols="3"  md="3">
                                            <v-btn
                                                color="primary"
                                                small
                                                @click="nova_tabela('item_edita','dialog_tabela')"
                                                v-if="(pro_pode_alterar)"
                                            >
                                                Novo
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>        

        
                        
                        </v-card-title>
        
                        <v-card-subtitle>
                        </v-card-subtitle>
        
                        <v-card-text class="pa-1"  v-if="card_titulo">
                        <div>
                            <v-row>
                                <v-col>
                                    <v-card>
                                    <!-- passo 5 monto a lista -->
                                        <v-data-table
                                        v-model="selected"
                                        :single-select="false"
                                        item-key="CODIGO"
                                        @click:row="seleciona_linha"
                                        :show-select="true"

                                        :headers="items_header"
                                        :items="items_lista"
                                        

                                        :height="retorna_altura((isMobile)? 340+altura_rodape : ((mostrar_rodape) ? 370 : 310+altura_rodape ))"

                                        class="elevation-1"
                                        :items-per-page="-1"
                                        :footer-props="{
                                        'items-per-page-options': [100,200,300,900,-1]
                                        }"
                                        :hide-default-footer="(!rodape)"

                                        disable-sort
                                        :mobile-breakpoint="55"
                                        
                                        :search="search"
                                        dense
                                        fixed-header
                                        >
                                        <!-- :height="(isMobile) ? '300' : (items_lista.length>0) ? '390' : ''" -->

                                            <template slot="no-data">
                                            <div></div>
                                            </template>


                                            <template v-slot:item.FORMA_PAGAMENTO="{ item }">
                                                <span class="font-weight-bold">
                                                  {{ item.FORMA_PAGAMENTO }}
                                                </span>
                                            </template>

                                            <template v-slot:item.x_DATA_EFETUADO="{ item }">
                                                <span class="green--text font-weight-bold">
                                                  {{ item.x_DATA_EFETUADO }}
                                                </span>
                                            </template>

                                            <template v-slot:item.VALOR_UM="{ item }">
                                                <span class="green--text font-weight-bold">
                                                  {{ item.VALOR_UM }}
                                                </span>
                                            </template>

                                            <template v-slot:item.VALOR_DOIS="{ item }">
                                                <span class="blue--text font-weight-bold">
                                                  {{ item.VALOR_DOIS }}
                                                </span>
                                            </template>

                                            <template v-slot:item.PROFISSIONAL="{ item }">
                                                <span class="font-weight-bold">
                                                 {{ item.PROFISSIONAL }}
                                                </span>
                                            </template>

                                            <template v-slot:item.x_DATA="{ item }">
                                                <span class="font-weight-bold">
                                                 {{ item.x_DATA }}
                                                </span>
                                            </template>

                                            <template v-slot:item.DATA_ALERTA="{ item }">
                                                <span class="red--text" v-if="(dif_datas(today, item.DATA_ALERTA)>=0)"
                                                >  <strong> {{ getFormatDate(item.DATA_ALERTA) }} </strong> </span>
                                                <span  v-else
                                                > {{ getFormatDate(item.DATA_ALERTA) }} </span>
                                            </template>
                                            
        
                                            <template v-slot:item.actions="{ item }">
                                                <v-icon
                                                    class="mr-2"
                                                    @click="editar_tabela(item, 'item_edita', 'dialog_tabela')"
                                                    color="blue"
                                                    :size = "size_mobile"
                                                    v-if="(pro_pode_alterar)"
                                                >
                                                mdi-pencil
                                                </v-icon>
                                            </template>
        


                                            <!-- //passo 6 personalizo as acoes dos botoes na lista  INICIO-->
                                            <template v-slot:item.actions_clock="{ item }">
                                                <v-tooltip 
                                                    bottom
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                        class="mr-2"
                                                        :size = "size_mobile"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        color="blue"
                                                        @click="tabela('ANTIGO_HORARIO','SUB',item), card_titulo='Horários'"
                                                    > 
                                                    <!-- :color="item.CONTRATO ? 'blue': 'grey'" -->
                                                    mdi-clock-time-four-outline 
                                                    </v-icon>
        
                                                    </template>
                                                    <span> Horários</span>
                                                </v-tooltip>
                                            </template>
        

                                            
                                            <template v-slot:item.actions_proced="{ item }">
                                                <v-tooltip 
                                                    bottom
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                        class="mr-2"
                                                        :size = "size_mobile"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        color="blue"
                                                        @click="tabela('VALORES_AMB','SUB',item), card_titulo='Procedimentos'"
                                                    > 
                                                    mdi-format-list-checkbox 
                                                    </v-icon>
        
                                                    </template>
                                                    <span> Procedimentos</span>
                                                </v-tooltip>
                                            </template>


                                            <!-- //passo 6 personalizo as acoes dos botoes na lista  FIM -->
        
                                        </v-data-table>
                                    </v-card>
        
                                </v-col>
                            </v-row>
        
                            <!-- row do rodape -->
                            <v-row dense v-if="mostrar_rodape" class="" ref="rodape_soma" id="rodape_soma">
                              <!-- mostro os cards de somatorias -->
                              <v-col 
                                class="py-0 ml-3"
                                cols="12"
                                md="11"
                              >

                              <v-container
                              class="px-0"
                              >
                              <v-row>
                                <v-col
                                  class="pa-0"
                                >
                                
                                <v-chip-group 
                                  active-class="deep-purple accent-4 white--text"
                                  column
                                  class="pl-0"
                                  >

                                  <v-chip 
                                    v-for="(item,  index ) in items_somatorias"
                                    dark label
                                    large
                                    :class="retorna_cor(item.CAMPO)"
                                    @click="search=item.CAMPO.toLowerCase()"
                                  >
                                  <!-- :class="[(item.CAMPO == 'TOTAL') ? 'blue' : 'grey']" -->
                                  
                                  <span class="mt-n3 fs-12">{{ item.CAMPO }} </span>
                                  <span class="mt-n6 ml-1  white--text text-size-4 count_item_2 fs-14"> {{ item.QT }} </span>

                                  <h5 class="mt-5 ml-n8" v-if="(item.VALOR!='NaN')">
                                    {{ getFormatCurrency(item.VALOR,'N') }}
                                  </h5>
                                  <!-- <div style="position: absolute; top: 90%; left: 50%; transform: translate(-50%, -50%);">Texto Sobreposto</div> -->
                                  
                                  </v-chip>



                                </v-chip-group>
                                </v-col>
                              </v-row>
                              </v-container>

                              </v-col>
                            </v-row>

                        </div>
                        </v-card-text>

                        <v-card-actions v-if="pro_tabela=='CLIENTES'">
                          <v-spacer/>
                          <v-btn color="primary" small @click="session_selecionados">
                            {{ (this.selected?.length>1) ? 'selecionados':'selecionar' }} 
                          </v-btn>
                        </v-card-actions>
                    </v-card>
                  </v-col>     
                </v-row> 

                <span class="fs-minimo"  clas>Lembretes</span>
              </v-container>  

        </v-card>
    
      </v-container>
    </v-dialog>
        <!-- </v-dialog> -->
      </div> 
    </div>
    </template>
    
    <script>
    
    import DSirotinas from '../assets/js/DSi_rotinas'//chamo funcao do arquivo
    import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
    import {  somatoria_lista } from '../assets/js/DSi_basico'
    import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
    
    
    export default {
    
    
      props:{
        aberto: Boolean,
        salvou: Boolean,
        pro_tabela: String,
        pro_titulo: String,
        show_menu  : Boolean,

        pro_dados_paciente: Object,
        pro_dados_proceds: Array,
        pro_obj_1: Object,
        pro_obj_menus: Object,
        pro_modo_edicao: Boolean,
        pro_pode_alterar: Boolean,
        pro_tag:String,
      },
    
      data: () => ({    

        obj_user: {},//dados do operador

        vai_imprimir: false,

        def_parcelas : [],
        lista_parcelas:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
        items_tabela_formas: ['DINHEIRO', 'CREDITO','DEBITO','PIX','BOLETO','CONVENIO','GRATIS','CHEQUE','CORTESIA','OUTROS','PERMUTA','DEPOSITO'],
        mostrar_rodape: false,

        cidade_digitou:'',

        antigos: false,    
        // aberto: true,
        nome_digitou:'',
        progress_user: false,
        find_start:false,

        rodape:false,
        altura_rodape: 0,

        cor_fundo: 'primary lighten-0',

        isMobile: false,

        colunas: 12,

        user:[],

        w_data: '99px',
        w_fone: '125px',
        w_cpf: '120px',
        w_cnpj: '150px',
        w_nome: '220px',
        w_situacao: '60px',
        w_valor: '90px',

        // ------------ TABELAS INICIO ---------------
        icone_tabela_sel:'',
        selected: [],

        selectedItem: -1,
        card_titulo:'',
        dialog_tabela: false,
        tabela_selecionada:'',
        search: '',

        lista_tabelas:[],
        selected: [],
        items_lista:[],
        items_header: [],
        items_editaveis: [],
        items_count:[],
        item_edita: {},

        sub_codigo:0,
        sub_descricao:'',

        valido: false,
        
        size_mobile:22,

        notnullRules: [
          v => !!v || 'necessário'
        ],

        noRules:[v => true],


        // ------------ TABELAS FIM ---------------
    
        snackbar2: false,
        snack_text2: 'Nova Mensagem',
        snack_color2: "success",
    

        count_alerta: 0,

    
        hoje :'',
        hoje_week:'',
        hora :'',
        data_hora :'',
        today :'',
        quem_alterou:'',
    
      }),
    
    
      created(){
        this.onCreated()
      },

    
      watch:{
   
        dialog_tabela(val){
          this.$set(this.item_edita, 'x_VEZES', 1)
          this.$set(this.item_edita, 'x_TIPO', true)

          this.$set(this.item_edita, 'x_EFETUADO'    , ( this.item_edita.EFETUADO == 'S') ? true : false)

          this.def_parcelas = []

        },

        rodape(a){
          if (a){
            this.altura_rodape = 120
          }
          else{
            this.altura_rodape = 0
          }
        },

        count_alerta(a){
          this.$emit('muda_count_lembretes', a)
        },

        aberto(a){
            // alert(this.pro_tabela)
            if (a){
                this.ultimo_click()
            }
            
        }
        
      },
    

    
      methods:{


        verifica_vezes(){


          // limpo variaveis
          this.def_parcelas = []


          let pago  = this.item_edita.x_EFETUADO
          let vezes = this.item_edita.x_VEZES
          let tipo  = this.item_edita.x_TIPO

          let valor = parseFloat(DSibasico.strtofloat(this.item_edita.VALOR_UM));//passa para float
          let valor_string 


          //copio o objeto
          let copia =  Object.assign({}, this.item_edita)


          if (vezes>1){
              // alert('mais')

              if (tipo){
                // alert('x')

                valor = valor * vezes
                valor_string = DSibasico.floatTostring(valor)//para para string
                
                this.$set(this.item_edita, 'x_TOTAL'    , valor_string)


                // defino o valor no copia
                this.$set(copia, 'VALOR_UM'   , this.item_edita.VALOR_UM)
              }
              else{
                // alert('/')

                valor = valor / vezes
                valor_string = DSibasico.floatTostring(valor)//para para string
                
                this.$set(this.item_edita, 'x_TOTAL'    , valor_string)


                // defino o valor no copia
                this.$set(copia, 'VALOR_UM'   , valor_string)
              }


              //percorre a quantidade adicionando os valores no array
              for (let i = 0; i < vezes; i++){

                let copia_aqui =  Object.assign({}, copia)

                // defino alguns valores
                this.$set(copia_aqui, 'SEQUENCIA'  , `${parseInt(i+1)}/${vezes}` )
                this.$set(copia_aqui, 'DATA'  , DSibasico.incMonth(this.item_edita.DATA,i,'us') )

                if (!pago){
                  this.$set(copia_aqui, 'EFETUADO'       , 'N')
                  this.$set(copia_aqui, 'DATA_EFETUADO'  , '')
                }
                


                // //adiciona no array o objeto
                this.def_parcelas.push(copia_aqui)
              }

              // console.log('def_parcelas:', this.def_parcelas);


          }
          else{
            // alert('um')
            
            this.$set(this.item_edita, 'x_TOTAL'    , this.item_edita.VALOR_UM)

            if (!pago){
                this.$set(this.item_edita, 'EFETUADO'       , 'N')
                this.$set(this.item_edita, 'DATA_EFETUADO'  , '')
            }
            else{
                this.$set(this.item_edita, 'EFETUADO'       , 'S')
                this.$set(this.item_edita, 'DATA_EFETUADO'  , this.today)
            }
          }
        },

        retorna_cor(campo){

          if (campo == 'TOTAL'){
            return 'blue'
          }
          if (campo == 'EFETUADO'){
            return 'green'
          }
          if (campo == 'PENDENTE'){
            return 'red'
          }
          else{
            return 'grey'
          }

        },



        getFormatCurrency(v,string){
          if (v){
            return DSibasico.getFormatCurrency(v, string)
          }
          else{
            return v
          }
          
        },

        async replace_array_soma(){

          // aqui substituo os campos especificos do array somatoria

          let tabela = this.tabela_selecionada

          const jsonString = JSON.stringify(this.items_somatorias);

          if (tabela == 'LANCAMENTOS'){
            const novaString = jsonString.replace(/"CAMPO":\s*"N"/g, '"CAMPO": "PENDENTE"')
                                        .replace(/"CAMPO":\s*"S"/g, '"CAMPO": "EFETUADO"');

            const novoArray = JSON.parse(novaString);


            this.items_somatorias = await novoArray
          }
        },

        session_selecionados(){

          let item = this.selected
          if (this.selected?.length>1){
            // console.log('varios,', item);
          }
          else{
            // console.log('um:', item);
          }

          sessionStorage['pacientes_selecionados']  =  JSON.stringify(item)

          this.abrir()
        },

        retorna_altura(menos){
          // let r = '100vh'  
          // let r = '720'
         
          let r = (window.innerHeight - menos)
          let height = window.innerHeight;

          return r
        },

        onChange(tipo){
            // console.log('onChange:', tipo);

            if (tipo == 'proced_lancamentos'){
                this.changePROCEDIMENTO(this.item_edita.CATEGORIA)
            }
        },


        changePROCEDIMENTO(CATEGORIA){

            //acha determinado valor no array de objeto com base no campo codigo
            let acha =  this.pro_dados_proceds.filter(x => x.CATEGORIA === CATEGORIA); //clonando array copiar array
            acha = acha[0]
            console.log('acha:', acha);

            this.$set(this.item_edita, 'CODIGO_PROCED', acha.CODIGO)        
   

        },

        async onCreated(){

            this.isMobile = window.innerWidth < 600
            this.refresh_data_hora()
            this.permite_items()

            await this.logado()
            
        },


        async clicou_em(acao){

           if (!acao){
            return false
           }


            if (this.pro_modo_edicao){
                await this.abrir()
            }

            else if (acao == 'verifica_vezes'){
              this.verifica_vezes()
            }


            if (acao == 'cancelar'){
                this.dialog_tabela = false
            }
            else if (acao == 'salvar'){ 

               await this.salvar_tabela(this.tabela_selecionada, this.item_edita, 'dialog_tabela')
               await this.modal_salvar(true, this.tabela_selecionada)
            }
            else if (acao == 'excluir'){

                await this.remover_tabela(this.tabela_selecionada, this.item_edita,  this.card_titulo) 
                await this.modal_salvar(true, this.tabela_selecionada)
                this.dialog_tabela = false
            } 


        },

        async ler_sessions(){

             await this.$set(this.user, 'CODIGO', sessionStorage['8ukjkljdfai'])

            this.obj_user = await DSibasico.retorna_obj_sessions('obj_user')
        },
        

        permite_items(){
        //passo 1 monto os items e suas tabelas e permissoes

            let s = []

            this.lista_tabelas = [
            { text: 'Lembretes', count: 0 , icon: 'mdi-bell', click:'ALERTA' },
            { text: 'Lançamentos', count: 0 , icon: 'mdi-dots', click:'LANCAMENTOS' },
            ]

        },

        async tabela(tabela, item){
        //passo 3 executo a acao quando clicar nos items e monto os campos editaveis e os campos do data-table

            // console.log(item);
            // this.icone_tabela_sel = item.icon
            
            if (!item){
             sessionStorage['tabela_clicou']   = tabela
             sessionStorage['tabela_titulo']   = this.card_titulo
            }

            this.rodape = false

            this.search = ''//limpa filtro

            this.tabela_selecionada = tabela
            this.mostrar_rodape     = false


            //limpa array
            this.items_lista       = [] //recebe os dados do sql
            this.items_header      = [] //campos que tem que mostrar no header
            this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header

        
            if (tabela=='CLIENTES'){


              this.rodape = true
              this.icone_tabela_sel = 'mdi-user'


              this.items_header = [

                  // { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
                  { text: 'IDE', value: 'CODIGOCLIENTE', width: '75px' },
                  { text: 'Nome', value: 'NOME', tag:'v-text', type:'date', cols:'2',rule:'S' , width: '300px'},
                  { text: 'Celular', value: 'CELULAR',width:'130px'},
                  { text: 'Fone', value: 'FONE1',width:'130px'},
                  { text: 'Cidade', value: 'CIDADE',width:'150px'},
                  { text: 'Nascimento', value: 'x_DATANASCIMENTO' },

              ]

              // clono os items
              this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

              let quero = this.nome_digitou
              quero = quero.toLocaleLowerCase()
              
              let cidade = this.cidade_digitou
              cidade = cidade?.toLocaleLowerCase()
              let whereCidade = ``
              if (cidade){ whereCidade = `(CLIENTES.CIDADE CONTAINING '${cidade}') AND` }

              let ide   = null
              if (!isNaN(quero)) {
                ide = quero
              } 

              if (quero){

                let where = `
                  WHERE
                  (CLIENTES.ATIVO <> 'X')AND
                  ${whereCidade}
                  ((CLIENTES.CODIGOCLIENTE = ${ide} )OR
                  (LOWER(CLIENTES.NOME) ${(this.find_start)? 'STARTING':'CONTAINING'}   '${quero}'))
                  `

                let sql = `select CODIGO, NOME, CODIGOCLIENTE, CELULAR, FONE1, CIDADE from CLIENTES ${where}`

                // console.log( tabela+' : '+sql);
                this.progress_user =  true
                await this.crud_abrir_tabela(sql,'items_lista')
                this.progress_user = false
              }

            }

            else  if ((tabela=='LANCAMENTOS') && (this.pro_tag == 'normal')) {

              this.col_menu = 1

              //limpa campos sub
              this.sub_codigo    = 0
              this.sub_descricao = ''

              this.items_header = [

                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
                  { text: '..', value: 'menu', sortable: false, type:'hide', cols:'0',width:'5px', click:'agora'},
                  { text: 'Codigo', value: 'CODIGO', type:'number', cols:'0' },
                  { text: 'Seq', value: 'SEQUENCIA', tag:'v-text', type:'text', cols:'1'},
                  // { text: 'EF', value: 'EFETUADO', tag:'v-text', type:'text', cols:'1'},
                  { text: 'Vencimento', value: 'x_DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data },
                  { text: 'Pago', value: 'x_DATA_EFETUADO', tag:'v-text', type:'date', cols:'2', width: this.w_data,},
                  // { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data },
                  { text: 'Valor', value: 'VALOR_UM', tag:'v-text', type:'text', cols:'2',rule:'S', upper:'N', click:'verifica_vezes',width: '99px' },
                  { text: 'X', text_2:'/', value: 'x_TIPO', tag:'v-checkbox', cols:'1',rule:'S', upper:'N', class_col:'',click:'verifica_vezes', style_tag: {'margin-left': '-4px'} },

                  { text: 'Parcelas', value: 'x_VEZES',
                      type:'text',
                      tag:'v-select',
                      tag_items: this.lista_parcelas,
                      cols:'1',rule:'S' ,
                      click:'verifica_vezes',
                      class:'ml-n4'
                  },
                  // { text: 'Total', value: 'x_TOTAL', tag:'v-chip-value', color:'', cols:'1', click:'', class_col:'px-0', class:'ml-n3', hint:''},
                  { text: 'Forma', value: 'FORMA_PAGAMENTO',
                      type:'text',
                      tag:'v-select',
                      tag_items: this.items_tabela_formas,
                      cols:'3',rule:'S' ,
                      click:'verifica_vezes'
                  },
                  // { text: 'CATEGORIA', value: 'CATEGORIA', tag:'v-text', type:'text', cols:'3',rule:'S', upper:'S', width: this.w_nome },
                  // { text: 'OPERACAO', value: 'OPERACAO', tag:'v-text', type:'text', cols:'3',rule:'S', upper:'S', width: this.w_nome },
                  // { text: 'PROFISSIONAL', value: 'PROFISSIONAL', tag:'v-text', type:'text', cols:'3',rule:'S', upper:'S', width: this.w_nome },
                  { text: 'Categoria', value: 'CATEGORIA', tag:'v-text', type:'text', cols:'5',rule:'S', upper:'S', width: '290px', click:'verifica_vezes' },
                  { text: 'Descrição', value: 'DESC_PROCEDIMENTO', tag:'v-text', type:'text', cols:'7',rule:'N', upper:'S', width: '290px', click:'verifica_vezes' },
                  // { text: 'LANCOU', value: 'LANCOU', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S', width: '220px' },
                  { text: 'Alterou', value: 'ALTEROU', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S', width: '220px' },

              ]

              // clono os items
              this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

              // removo da edição
              // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "PROFISSIONAL") , 1);//removo a coluna senha
              this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "x_DATA") , 1);//removo a coluna senha
              this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "x_DATA_EFETUADO") , 1);//removo a coluna senha
              this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "LANCOU") , 1);//removo a coluna senha
              // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "ALTEROU") , 1);//removo a coluna senha
              this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "SEQUENCIA") , 1);//removo a coluna senha


              // ADICIONO SOMENTE NA EDIÇÃO
              this.items_editaveis.unshift(
                  { text: 'Vencimento', value: 'DATA', tag:'v-text', type:'date', cols:'2',click:'verifica_vezes' },

                  // { text: 'EFETUADO', text_2:'EFETUADO', value: 'x_EFETUADO', tag:'v-checkbox', cols:'1',rule:'N', upper:'N', class_col:'red', class_tag:'mt-9 ml-10',click:'verifica_vezes', if:'x_EFETUADO' },
                  // { text: 'EFETUADO', text_2:'EFETUADO', value: 'x_EFETUADO', tag:'v-checkbox', cols:'1',rule:'N', upper:'N', class_col:'blue', class_tag:'ml-10',click:'verifica_vezes', else:'x_EFETUADO' },
                  { text: 'EFETUADO', text_2:'PENDENTE', value: 'x_EFETUADO', tag:'v-checkbox', cols:'1',rule:'N', upper:'N', class_col:'', 
                    class_if:'x_EFETUADO', class_tag: 'mt-9 ml-10', class_tag2: 'ml-10', click:'verifica_vezes'},

                  { text: 'Efetuado', value: 'DATA_EFETUADO', tag:'v-text', type:'date', cols:'2', click:'verifica_vezes', if:'x_EFETUADO', class:'', class_tag:'ml-n10' },
              )



              this.items_header.splice(this.items_header.findIndex(x => x.value === "x_VEZES") , 1);//removo a coluna senha
              this.items_header.splice(this.items_header.findIndex(x => x.value === "x_TIPO") , 1);//removo a coluna senha

              let codigo = -1
              if (this.pro_dados_paciente){
                  codigo = this.pro_dados_paciente.CODIGOCLIENTE
              }
              

              let sql = `
                    SELECT
                      CODIGO,
                      CODIGO_CLIENTE,
                      CODIGO_PROCED,
                      CODIGO_CONSULTA,
                      CODIGO_PROFISSIONAL,
                      CODIGO_CONVENIO

                      COD_USER_RECEBEU,
                      COD_USER_LANCOU,

                      TIPO,
                      EFETUADO,
                      VISTO,
                      DATA,
                      DATA_LANCAMENTO,
                      DATA_EFETUADO,
                      
                      R_RECEBEU,
                      ALTEROU,
                      LANCOU,
                      CATEGORIA,
                      PROFISSIONAL,
                      VALOR_UM,
                      VALOR_DOIS,
                      FORMA_PAGAMENTO,

                      OPERACAO,
                      DESCRICAO,
                      DESC_PROCEDIMENTO,

                      SEQUENCIA
                    FROM
                      LANCAMENTOS
                    WHERE  (LANCAMENTOS.CODIGO_CLIENTE = ${codigo}) order by DATA desc, CODIGO desc`


              // console.log( tabela+' : '+sql);
              await this.crud_abrir_tabela(sql,'items_lista')

              await somatoria_lista(this, 'items_lista', 'items_somatorias', 'VALOR_UM', 'EFETUADO', false)

              await this.replace_array_soma()

              this.mostrar_rodape = true 
            }

            else  if (tabela=='LANCAMENTOS'){


                this.icone_tabela_sel = 'mdi-credit-card-multiple'

                this.col_menu = 1

                //limpa campos sub
                this.sub_codigo    = 0
                this.sub_descricao = ''

                this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
                    // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },
                    // { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2' },
                    { text: 'DATA', value: 'x_DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data },
                    // { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data },

                    { text: 'PROCEDIMENTO', value: 'CATEGORIA',
                          type:'text',
                          tag:'v-combobox',
                          tag_items: this.pro_dados_proceds,
                          tag_change: 'proced_lancamentos',
                          item_value: 'CATEGORIA',
                          item_text: 'CATEGORIA',
                          cols:'5',
                          rule:'S' 
                    },
                    
                    { text: 'PROFISSIONAL', value: 'PROFISSIONAL', tag:'v-text', type:'text', cols:'3',rule:'S', upper:'S', width: this.w_nome },
                    { text: 'VALOR PAGO', value: 'VALOR_UM', tag:'v-text', type:'text', cols:'2',rule:'S', upper:'N' },
                    { text: 'VALOR RECEBIDO', value: 'VALOR_DOIS', tag:'v-text', type:'text', cols:'2',rule:'N', upper:'N' },
                    { text: 'FORMA_PAGAMENTO', value: 'FORMA_PAGAMENTO', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S' },
                    
                    { text: 'LANCOU', value: 'LANCOU', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S', width: '330px' },
                    { text: 'ALTEROU', value: 'ALTEROU', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S', width: '330px' },
                    
                    { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },                    

                ]

                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo da edição
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "PROFISSIONAL") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "x_DATA") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "LANCOU") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "ALTEROU") , 1);//removo a coluna senha


                // ADICIONO SOMENTE NA EDIÇÃO
                this.items_editaveis.unshift(
                    { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2' },
                )



                let codigo = -1
                if (this.pro_dados_paciente){
                    codigo = this.pro_dados_paciente.CODIGOCLIENTE
                }
                // let sql = `select * from ${tabela} where  (LANCAMENTOS.CODIGO_CLIENTE = ${codigo}) order by DATA desc, FORMA_PAGAMENTO`
                let sql = `
                      SELECT
                        CODIGO,
                        CODIGO_CLIENTE,
                        CODIGO_PROCED,
                        CODIGO_CONSULTA,
                        CODIGO_PROFISSIONAL,
                        CODIGO_CONVENIO

                        COD_USER_RECEBEU,
                        COD_USER_LANCOU,

                        TIPO,
                        EFETUADO,
                        VISTO,
                        DATA,
                        DATA_LANCAMENTO,
                        DATA_EFETUADO,
                        
                        R_RECEBEU,
                        ALTEROU,
                        LANCOU,
                        CATEGORIA,
                        PROFISSIONAL,
                        VALOR_UM,
                        VALOR_DOIS,
                        FORMA_PAGAMENTO,

                        OPERACAO,
                        DESCRICAO,
                        DESC_PROCEDIMENTO
                      FROM
                        LANCAMENTOS
                      WHERE  (LANCAMENTOS.CODIGO_CLIENTE = ${codigo}) order by DATA desc, CODIGO desc, FORMA_PAGAMENTO`
            

                // console.log( tabela+' : '+sql);
                this.crud_abrir_tabela(sql,'items_lista')
            }
        
            else if (tabela=='MANIPULADOS'){


                this.icone_tabela_sel = 'mdi-spa'

                this.col_menu = 1

                //limpa campos sub
                this.sub_codigo    = 0
                this.sub_descricao = ''

                this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
                    // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },
                    { text: 'NOME DO MODELO', value: 'NOME', tag:'v-text', type:'text', cols:'12', width: this.w_data, rule:'S', upper:'S', focus:'S' },
                    { text: 'MODELO', value: 'MODELO',tag:'v-textarea', type:'text', cols:'12', rows:'14',rule:'S' },
                    
                ]

                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "CODIGO_OPERADOR") , 1);//removo a coluna senha

                // let sete_dias = this.incDay(this.today,7,'us')
                let sete_dias = this.incMonth(this.today,7,'us')
                let codigo = -1
                if (this.pro_obj_1){
                    codigo = this.pro_obj_1.CODIGO
                }
                let sql = `select * from MANIPULADOS  WHERE MANIPULADOS.codigo  = ${codigo}`
                


                // console.log( tabela+' : '+sql);
                this.crud_abrir_tabela(sql,'items_lista')
            }
        
            else if (tabela=='ALERTA'){

                this.count_novas()//atualiza o contador

                this.icone_tabela_sel = 'mdi-bell'

                this.col_menu = 1

                //limpa campos sub
                this.sub_codigo    = 0
                this.sub_descricao = ''

                this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
                    // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },
                    { text: 'DATA', value: 'DATA_ALERTA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'S' },
                    // { text: 'CODIGO_OPERADOR', value: 'CODIGO_OPERADOR', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'S' },
                    { text: 'DESCRIÇÃO', value: 'DESCRICAO',tag:'v-textarea', type:'text', cols:'12', rows:'3',rule:'S' },
                    
                ]

                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "CODIGO_OPERADOR") , 1);//removo a coluna senha

                // let sete_dias = this.incDay(this.today,7,'us')
                let sete_dias = this.incMonth(this.today,7,'us')

                let where = `WHERE (data_alerta BETWEEN '${this.today}' and '${sete_dias}'
                              AND codigo_operador = ${this.user.CODIGO})`
                if (this.antigos){
                    where = `where codigo_operador = ${this.user.CODIGO}`
                }
                let sql = `select * from alerta ${where} order by  data_alerta`

                // let sql = `select * from alerta where alerta.data_alerta between '${this.today}' and '${sete_dias}' and codigo_operador = ${this.user.CODIGO}  order by alerta.data_alerta`


                // console.log( tabela+' : '+sql);
                this.crud_abrir_tabela(sql,'items_lista')
            }

        },

        async before_update(tabela){
          // passo 8 antes de salvar verifico alguns campos necessarios em determinada tabela

          // VERIFICA CAMPOS NUMERICOS QUE NAO PODEM IR VAZIOS
          let VERIFICAR = [
          'LANCAMENTOS|DATA|null',
          'LANCAMENTOS|DATA_EFETUADO|null',
          'LANCAMENTOS|DATA_LANCAMENTO|null',
          'LANCAMENTOS|CODIGO_CONSULTA|0',
          'LANCAMENTOS|COD_USER_LANCOU|0',
          'LANCAMENTOS|COD_USER_RECEBEU|0',
          'LANCAMENTOS|CODIGO_PROCED|1',
          ]
          
        
          let CAMPOS      = []
          let CAMPO_VER   = ''
          let CAMPO_VALOR = ''
          for (let i = 0; i < VERIFICAR.length; i++) {
             
             CAMPOS      = VERIFICAR[i].split("|");//divide
             CAMPO_VER   = CAMPOS[1]
             CAMPO_VALOR = this.item_edita[CAMPO_VER]

             if (tabela==CAMPOS[0]){//verifica se é a tabela selecionada
                if (!CAMPO_VALOR){//verifica se vazio
                  this.$set(this.item_edita, CAMPOS[1], CAMPOS[2] ) 
                }
             }
          }


          if (tabela == 'LANCAMENTOS'){
            let quem_alterou = await DSibasico.returna_quem_alterou()
            this.$set(this.item_edita, 'ALTEROU', quem_alterou)

          }
        },

        async ultimo_click(){

          let tabela = sessionStorage['tabela_clicou']

          if (this.pro_tabela){
            this.card_titulo = this.pro_titulo
            await this.tabela(this.pro_tabela,'')

            sessionStorage['tabela_clicou'] = this.pro_tabela
          }
          else if (tabela){
              this.card_titulo = sessionStorage['tabela_titulo'] 
              await this.tabela(tabela,'')
          }


          //   ja abre em modo edicao
          if (this.pro_modo_edicao){

              if (this.pro_obj_1){
                // alert('editar')
                this.editar_tabela(this.pro_obj_1, 'item_edita', 'dialog_tabela')
              }
              else{
                // alert('novo')
                this.nova_tabela('item_edita','dialog_tabela')
              }
         }

        },

        meu_keyup(event,campo,upper){
  
          //identifica se o campo é double e muda o display
          if ((campo.includes('double_') || campo.includes('VALOR')) ){
              this.item_edita[campo] = DSibasico.moeda(event)
              // @keyup="editedItem.double_credito = moeda($event)"
          }
          else if (campo.includes('CEP')) {
  
              this.consulta_cep(this.item_edita[campo])    
          }
          else{
              if ((campo.includes('EMAIL')==false) &&
              (campo.includes('SENHA')==false) &&
              (campo.includes('SENHA_WEB')==false)){
  
                  if (upper !=='N'){
                  this.allUpper(event, campo)
                  }
                  
              }
          }
        },

        allUpper(event, field){
              let a = event.target.value;//captura o valor do event
              if (a!==''){  
                  a = a.toUpperCase();
                  this.item_edita[field] = a;
              } 
        },

        firstUpper(event, field){
              // console.log(event);
              let a = event.target.value;//captura o valor do event
              if (a!=='' && a.length==1){  
                a = a.toUpperCase();
                this.item_edita[field] = a;
              } 
        },


        getVmask(qual){
            
            if (qual == 'cel'){
                return "(##) #####-####"
            }
            else if (qual == 'fone'){
                return "(##) ####-####"
            }
            else if (qual == 'cnpj'){
                return "##.###.###/####-##"
            }
            else if (qual == 'cpf'){
                return "###.###.###-##"
            }
            else if (qual == 'cep'){
                return "#####-###"
            }
            else{
                return ""
            }
          },


          async count_novas(){
                
                let sql = `select count(codigo) from alerta where alerta.data_alerta = 'today' and codigo_operador = ${this.user.CODIGO}`

                //  console.log(sql);
                await this.crud_abrir_tabela(sql, 'items_count' )

                // console.log(this.items_count[0].COUNT);
                this.count_alerta = await this.items_count[0].COUNT

            }, 

        refresh_data_hora(){
        
                let a = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });//formato dd/mm/yyyy  
                // console.log('hoje = '+ a);
                this.hoje = a;

                a =   this.hoje.slice(3, 5) + '/'+this.hoje.slice(0, 2)+'/'+this.hoje.slice(6, 10) ;//formato mm/dd/yyyy  
                // console.log('hoje_mm_dd_yyyy = '+ a);
                this.hoje_mm_dd_yyyy = a;


                // a = new Date().toLocaleTimeString('pt-Br',{ timeStyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });
                a = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
                // console.log('hora = '+ a);
                this.hora = a;

                a = this.hoje + '_'+ this.hora;
                // console.log('data_hora = '+ a);
                this.data_hora = a;


                a = this.hoje.slice(6, 10) +'-'+ this.hoje.slice(3, 5) +'-'+ this.hoje.slice(0, 2) ; //formato us yyyy-mm-dd
                // console.log('today = '+ a);
                this.today = a;
        },

        async define_defaults(tabela){
            // passo 7 defino valores default para alguns campos de determinada tabela
            
            this.refresh_data_hora()

            let quem_alterou = await DSibasico.returna_quem_alterou()
            
            if (tabela=='ALERTA'){
             this.$set(this.item_edita, 'CODIGO_OPERADOR', this.user.CODIGO)
            }
            
            else if (tabela=='MANIPULADOS'){
             this.$set(this.item_edita, 'CODIGO_PROFISSIONAL', sessionStorage['getCodigoDoc'])
            }
            
            else if (tabela=='LANCAMENTOS'){
             this.$set(this.item_edita, 'CODIGO_CLIENTE'        , this.pro_dados_paciente.CODIGOCLIENTE)
             this.$set(this.item_edita, 'DESCRICAO'             , this.pro_dados_paciente.NOME)
             this.$set(this.item_edita, 'CODIGO_CONVENIO'       , this.pro_dados_paciente.CODIGOCONVENIO)
             this.$set(this.item_edita, 'CONVENIO'              , this.pro_dados_paciente.CONVENIO)
             this.$set(this.item_edita, 'CODIGO_PROFISSIONAL'   , this.pro_dados_paciente.CODIGOMEDICO)
             this.$set(this.item_edita, 'PROFISSIONAL'          , this.pro_dados_paciente.PROFISSIONAL)
             this.$set(this.item_edita, 'DATA'                  , this.today)
             this.$set(this.item_edita, 'DATA_LANCAMENTO'       , this.today)
             this.$set(this.item_edita, 'DATA_EFETUADO'         , this.today)
             this.$set(this.item_edita, 'LANCOU'                , quem_alterou)
             this.$set(this.item_edita, 'ALTEROU'               , quem_alterou)

             this.$set(this.item_edita, 'FORMA_PAGAMENTO'       , 'DINHEIRO')
             this.$set(this.item_edita, 'OPERACAO'              , 'AGENDA')
 
             this.$set(this.item_edita, 'CATEGORIA'             , '')
             this.$set(this.item_edita, 'DESC_PROCEDIMENTO'     , '')
             this.$set(this.item_edita, 'VALOR_UM'              , '')
 
             this.$set(this.item_edita, 'TIPO'                  , 'E')
             this.$set(this.item_edita, 'EFETUADO'              , 'S')
             this.$set(this.item_edita, 'VISTO'                 , 'N')
             this.$set(this.item_edita, 'SEQUENCIA'             , '1')
             this.$set(this.item_edita, 'LANCOU'                , quem_alterou)
             this.$set(this.item_edita, 'ALTEROU'               , quem_alterou)
 
             this.$set(this.item_edita, 'x_TIPO'                , 'X')
             this.$set(this.item_edita, 'x_VEZES'               , 1)
             this.$set(this.item_edita, 'x_EFETUADO'            , true)

             DSibasico.returna_quem_alterou()
            }

        },

        async seleciona_linha(row) {
          
          if (this.selected?.length <= 1){
            // this.selected= [{"CODIGO" : row.CODIGO}] //via v-modal no data-table
              this.selected  = [ Object.assign({}, row)] 
          }
        },
    
        async logado(){
          let r = await DSirotinas.logado(this)

          if (r=='S'){
             
             await this.ler_sessions()
             await this.count_novas()

          }

        },
    
        async abrir(){
          // this.aberto=!this.aberto
          //limpa as coisas
          this.nome_digitou = ''
          this.selected = []
          this.$emit('muda_aberto_lembretes', !this.aberto)
        },
    
        async modal_salvar(estado, tabela){
          // this.aberto=!this.aberto
          this.$emit('modal_salvar', estado, tabela)
        },
    
    
        manda_emit(acao,a,b,c){
          this.$emit('emit_executa',acao,a,b,c)
        }, 
    
    

    
         getFormatTime(hora){
            if (hora !== undefined){
               let shora =   hora.substring(0,2) + ':'+ hora.substring(3,5)//formato a hh:mm
               return shora 
            }else{
              return ''
            }  
          },
    
          getFormatDate(data){
            let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
            return dia 
          },
    
    
    
        // --------------------------------SQL-------------------------------------
    
        //ABRE OS DADOS DA TABELA
        async crud_abrir_tabela(sql, items_name){
            await DSisql.crud_abrir_tabela(this, sql, items_name)
    
            // console.log(this.items_mensagens);
        }, 
    
        async crud_sql (sql) {
            await DSisql.crud_sql(this, sql)
        },
    
        alerta(text,color){
            DSibasico.alerta(this, text, color)
        },

        dif_datas(a,b){
            return DSibasico.dif_datas(a,b)
        },
    
        incDay(data,dias,pt){
            return DSibasico.incDay(data,dias,pt)
        },
    
        incMonth(data,dias,pt){
            return DSibasico.incMonth(data,dias,pt)
        },
    
        alerta2(text,color){
            // alert(text);
             this.snackbar2 = true;
             this.snack_text2= text;
    
             this.snack_color2 = color;
        },
    
        define_data_hora(){
            DSibasico.define_data_hora(this)//passo o this para que la ele defina as variaveis daqui
        },
    


           //---------------importando funcoes sql ----------------------- INICIO
          //EXECUTAR UM SQL TIPO UPDATE
          async crud_sql (sql) {
              await DSisql.crud_sql(this, sql)
          },
  
          async tabela_create(tabela, obj_edita){
              await DSisql.tabela_create(this, tabela, obj_edita)
          },
  
          async tabela_update(tabela, obj_edita){
              await DSisql.tabela_update(this, tabela, obj_edita)
          },
  
          //ABRE OS DADOS DA TABELA
          async crud_abrir_tabela(sql, items_name){
              await DSisql.crud_abrir_tabela(this, sql, items_name)
          }, 
  
          //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
          async salvar_tabela(tabela, obj_edita, dialog_name){

              await this.before_update(this.tabela_selecionada)//trato alguns campos necessarios

              // await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)

              if (this.def_parcelas.length>0){//caso tenha gerado parcels multiplas
                
                // Transformando o loop for em um loop assíncrono
                await Promise.all(this.def_parcelas.map(async (def_parcela, i) => {
                    // console.log('this.def_parcelas[i]:', i, ' - ', def_parcela);
                    await DSisql.salvar_tabela(this, tabela, def_parcela, dialog_name);
                }));
              }
              else{
                await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)//modo normal
              }

          },
  
          async remover_tabela(tabela, obj_edita, titulo){
              await DSisql.remover_tabela(this, tabela, obj_edita, titulo)
          },
  
          async editar_tabela(item, obj_edita_name, dialog_name) {
              await this.seleciona_linha(item)
              await DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
          },
  
          nova_tabela(obj_edita_name,dialog_name){

             DSisql.nova_tabela(this, obj_edita_name,dialog_name)

             //define alguns valores default
             this.define_defaults(this.tabela_selecionada)
          },


      },
    
    
    }
    </script>
    
    <style>
    .container{
        /* max-width: 100%; */
      }
    
    .names{
      color: #424949;
    }
    
    
      .scrollable {
        overflow-y: auto;
        height: 90vh;
        background-color: blue;
      }
    
      /* box das mensagens */
      .chat-container{
        box-sizing: border-box;
        /* height: calc(100vh - 9.5rem); */
        /* height: 400px; */
        
        /*height: 50vh; /* 50% altura tela*/
        /* max-height: 600px; */
        /* width: 100vw; 100% largura tela*/
        overflow-y: auto;
        padding: 10px;
        background-color: #f2f2f2;
        /* background-color: white; */
        /* background-color: red; */
      }
      .message{
        /* margin-bottom: 3px; */
      }
      .message.own{
        text-align: right;
      }
      .message.own .content{
        /* background-color: lightgreen; */
        background-color: lightskyblue;
      }
      .chat-container .username{
        font-size: 14px;
        font-weight: bold;
    
      }
      .chat-container .content{
        padding: 2px 8px;
        background-color: white;
        /* background-color: lightgreen; */
        border-radius: 10px; /* bordas arrendondadas */
        display:inline-block;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12);
        max-width: 90%;
        word-wrap: break-word;
        font-size: 12px;
        color: black;
        }
      @media (max-width: 480px) {
        .chat-container .content{
          max-width: 60%;
        }
      }
    
      .hora{
        font-size: 9px;
      }

      .fs-7{
    font-size: 7px !important;
  }
  .fs-8{
    font-size: 8px !important;
  }
  .fs-9{
    font-size: 9px !important;
  }
  .fs-10{
    font-size: 10px !important;
  }
  .fs-11{
    font-size: 11px !important;
  }
  .fs-12{
    font-size: 12px !important;
  }
  .fs-13{
    font-size: 13px !important;
  }
  .fs-14{
    font-size: 14px !important;
  }
  .fs-15{
    font-size: 15px !important;
  }
  .fs-16{
    font-size: 16px !important;
  }
  .fs-17{
    font-size: 17px !important;
  }
  .bold{
    font-weight: bold !important;
  }

  .fs-minimo{
    font-size: 9px !important;
    color: #DCDCDC;
  }
    
    </style>